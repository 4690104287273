import axios from "axios";
import React, { useState, useEffect, useContext, createContext } from "react";
import { initializeApp } from "firebase/app";
import {
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  TwitterAuthProvider,
  signInAnonymously,
  onAuthStateChanged,
  GoogleAuthProvider,
  getAuth,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
} from "firebase/auth";
import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  connectFirestoreEmulator,
} from "firebase/firestore";
import {
  getStorage,
  connectStorageEmulator,
  listAll,
  ref as storageRef,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

import {
  getDatabase,
  ref,
  child,
  get,
  set,
  connectDatabaseEmulator,
  onValue,
  update,
} from "firebase/database";
import {
  closeModal as closeWaitModal,
  openModal as openWaitModal,
} from "../features/common/waitModalSlice";
import { useDispatch } from "react-redux";
import { showNotification } from "../features/common/headerSlice";
import mixpanel from "mixpanel-browser";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rtdb = getDatabase(app);
const functions = getFunctions(app);
const storage = getStorage(app);

if (shouldConnectAuthEmulator()) {
  //console.log("DEV??");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectDatabaseEmulator(rtdb, "localhost", 9000);
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}

function shouldConnectAuthEmulator() {
  // You could do any logic here to decide whether to connect to the emulator or not
  return process.env.REACT_APP_ENVIRONMENT === "DEV-Emulator";
}

const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("subsription: ", subscription);
  }, [subscription]);

  const listenToSubscriptionData = async () => {
    const subscriptionRef = ref(rtdb, "subscription/" + user.localId);
    onValue(subscriptionRef, async (snapshot) => {
      const subscriptionData = snapshot.val();

      // Safeguard for undefined subscription values
      if (!subscriptionData) return;

      setSubscription((prevSubscription) => {
        // Safeguard for undefined prevSubscription
        if (!prevSubscription) return { ...subscriptionData };

        // Check if faceData and selectedFaceIndex are the same
        const isSameFaceData =
          prevSubscription.synthFaces &&
          JSON.stringify(subscriptionData?.faceData) ===
            JSON.stringify(prevSubscription?.faceData) &&
          subscriptionData?.selectedFaceIndex ===
            prevSubscription?.selectedFaceIndex;

        if (isSameFaceData) {
          console.log(
            "Face data and selectedFaceIndex are the same, no need to rebuild"
          );

          // If face data hasn't changed, return the updated subscription without changing synthFaces or face
          return {
            ...subscriptionData,
            synthFaces: prevSubscription.synthFaces,
            face: prevSubscription.face,
          };
        } else {
          console.log(
            "Face data or selectedFaceIndex has changed, rebuilding face data"
          );

          dispatch(
            openWaitModal({
              title: "Please Wait... Loading Your Synths",
              icon: "clock",
            })
          );

          console.log("Get All Basic Faces STARTS");
          // Fetch all basic faces
          getAllFaces(subscriptionData.uid).then((synthFaces) => {
            console.log("Get All Faces THEN", synthFaces);

            //SET FACE TYPES
            let faceIndexes = Object.keys(synthFaces);
            faceIndexes.forEach((faceIndex) => {
              if (!subscriptionData.faceData[faceIndex]) {
                console.log(
                  "subscriptionData.faceData " + faceIndex + " is undefined"
                );
                return;
              }
              let trainingImages =
                subscriptionData.faceData[faceIndex]?.["type"];
              let status = subscriptionData.faceData[faceIndex]?.["status"];
              synthFaces[faceIndex]["type"] = trainingImages
                ? "Clone"
                : "Basic";
              synthFaces[faceIndex]["status"] = status ? status : "Ready";
              console.log(
                "synthFaces " +
                  faceIndex +
                  " is " +
                  synthFaces[faceIndex]["type"]
              );
            });

            // Update the selected face if synthFaces exist
            if (synthFaces && Object.keys(synthFaces).length > 0) {
              subscriptionData.synthFaces = synthFaces;
              console.log("(subscriptionData.synthFaces)");
              setSelectedFace(subscriptionData).then((face) => {
                subscriptionData.face = face;
                console.log("setSelectedFace Inside");
                dispatch(closeWaitModal());

                // Update the subscription after faces are set
                setSubscription({ ...subscriptionData });
              });
            } else {
              console.log("USER HAS 0 FACES");
              dispatch(closeWaitModal());
              setSubscription({ ...subscriptionData });
            }
          });

          // Return the current subscription while new data is fetched
          return { ...subscriptionData };
        }
      });
    });
  };

  const deleteFace = async (index) => {
    console.log("Delete Face");
    if (Object.values(subscription?.synthFaces).length < 2) {
      console.log("Can't delete last face");

      dispatch(
        showNotification({
          message: `Oops! Can't Delete Your Only Synth!`,
          status: 0,
        })
      );
      //Add Error Message Saying Cant Delete Last Face
    } else {
      dispatch(
        openWaitModal({
          title: "Please Wait... Deleting Face",
          icon: "clock",
        })
      );
      try {
        let updatedSubscription = { ...subscription };
        //delete the faceData object at the index
        delete updatedSubscription.faceData[index];
        delete updatedSubscription.synthFaces;

        //get a random face index from the remaining
        let randomFaceIndex = parseInt(
          Object.keys(updatedSubscription?.faceData)[0]
        );
        console.log("subscription/" + subscription?.uid);

        //remove the face file
        const deletingFace = storageRef(
          storage,
          subscription?.uid + "/faces/" + index + ".png"
        );
        await deleteObject(deletingFace);

        //update the subscription
        await update(ref(rtdb, "subscription/" + subscription?.uid), {
          faceData: updatedSubscription.faceData,
          selectedFaceIndex: randomFaceIndex,
        });

        dispatch(closeWaitModal());
      } catch (error) {
        dispatch(closeWaitModal());
      }
    }
  };

  useEffect(() => {
    if (user) {
      listenToSubscriptionData();
    }

    // eslint-disable-next-line
  }, [user]);

  const setSelectedFace = async (subscriptionData) => {
    let synthFaces = {};
    synthFaces = subscriptionData["synthFaces"];
    //get the face with index of subscriptionData['face']
    let selectedFace = synthFaces[subscriptionData["selectedFaceIndex"]];

    if (
      selectedFace &&
      (selectedFace["type"] === "Basic" || selectedFace["type"] === "Clone")
    ) {
      let res = await fetch(selectedFace["url"])
        .then((res) => res.blob())
        .then((blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              resolve(reader.result);
            };
          });
        });
      let base64 = await res;
      // setFace(`data:image/png;base64,${base64.split(",")[1]}`);
      return base64.split(",")[1];
    } else {
      if (!subscription?.faceData) {
        console.error("User is new");
        return false;
      }
      console.error("Fixing Invalid selected face", subscription?.faceData);
      let randomFaceIndex = parseInt(Object.keys(subscription?.faceData)[0]);
      console.log("random face index", randomFaceIndex);

      if (randomFaceIndex > -1) {
        await update(ref(rtdb, "subscription/" + subscription?.uid), {
          selectedFaceIndex: randomFaceIndex,
        });
      } else {
        console.error("COULD NOT FIX INVALID FACE DATA");
      }
      return false;
    }
  };

  const getAllFaces = async (uid) => {
    let synthFaces = {};
    //fireabse storage, get all images from folder

    const listBasicFaces = storageRef(storage, uid + "/faces/");
    let faces = await listAll(listBasicFaces);
    faces = faces.items;

    //iterate through faces
    for (let i = 0; i < faces.length; i++) {
      const face = faces[i];
      const url = await getDownloadURL(face);
      let faceIndex = face.name;
      faceIndex = parseInt(faceIndex.replace(".png", ""));

      synthFaces[faceIndex] = {
        type: "Basic",
        url: url,
        index: faceIndex,
      };
    }

    return synthFaces;
  };

  const handleUser = async (rawUser, credentials) => {
    // console.log("handle user");
    // console.log("handle user");
    try {
      if (rawUser) {
        const user = rawUser;

        const docRef = doc(db, "users", user.uid);

        mixpanel.alias(user.uid);

        const data = {
          uid: user.uid,
          displayName: user.displayName,
          ...user["providerData"][0],
          ...credentials,
          timeSlots: ["0900-1200", "1201-1800", "1801-2359"],
        };

        if (data["providerId"] === "google.com") {
          data["oauthAccessToken"] = null;
          data["oauthTokenSecret"] = null;
          data["oauthExpireIn"] = null;
          data["oauthIdToken"] = null;
        }

        if (Object.keys(credentials).length === 0) {
          setLoading(false);
          let userObj = await getDoc(docRef);
          let userRtdb = await get(child(ref(rtdb), `users/${user.uid}`));
          let userSub = await get(child(ref(rtdb), `subscription/${user.uid}`));
          //console.log(userRtdb.val());
          setUser({
            ...userObj.data(),
            ...userRtdb.val(),
          });
          setSubscription({
            ...userSub.val(),
          });
          // console.log();
        } else {
          await setDoc(docRef, data);
          await set(ref(rtdb, `users/${user.uid}`), data);
          setLoading(false);
          setUser(data);

          let userSub = await get(child(ref(rtdb), `subscription/${user.uid}`));
          userSub = userSub.val();
          console.log(userSub);
          if (userSub) {
            console.log("sub exists");
            setSubscription({
              ...userSub,
            });
          } else {
            console.log("sub not exists");
            await set(ref(rtdb, `subscription/${user.uid}`), {
              uid: user.uid,
              credits: 10,
              createdAt: Date.now(),
              status: "trial",
            });
            setSubscription({
              uid: user.uid,
              credits: 10,
              createdAt: Date.now(),
              status: "trial",
            });
          }
          console.log(data);
        }

        return data;
      } else {
        setLoading(false);
        setUser(false);
        return false;
      }
    } catch (error) {
      console.log("A BUG WITH CREATING USER SUB: ", error);
    }
  };

  const signinWithTwitter = () => {
    console.log("Signin with twitter");
    const twitterProvider = new TwitterAuthProvider();
    setLoading(true);
    return signInWithPopup(auth, twitterProvider).then((response) => {
      try {
        let additionalUserInfoObj = getAdditionalUserInfo(response);
        if (additionalUserInfoObj.isNewUser) {
          mixpanel.track("signup", {});
          window.fbq("track", "Lead", {
            currency: "USD",
          });
          console.log("META PIXEL: LEAD");
        }
      } catch (error) {
        console.log("error on checking if new user: ", error);
      }

      handleUser(response.user, response["_tokenResponse"]);
    });
  };

  const signinWithGoogle = () => {
    console.log("Signin with google");
    const googleProvider = new GoogleAuthProvider();
    setLoading(true);
    return signInWithPopup(auth, googleProvider).then((response) => {
      try {
        let additionalUserInfoObj = getAdditionalUserInfo(response);
        if (additionalUserInfoObj.isNewUser) {
          mixpanel.track("signup", {});
          window.fbq("track", "Lead", {
            currency: "USD",
          });
          console.log("META PIXEL: LEAD");
        }
      } catch (error) {
        console.log("error on checking if new user: ", error);
      }
      console.log("before handle user");
      handleUser(response.user, response["_tokenResponse"]);
      console.log("after handle user");
    });
  };

  const signInWithEmailandPass = (email, password) => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        handleUser(response.user, response["_tokenResponse"]);
        dispatch(
          showNotification({ message: `Successfully Login`, status: 1 })
        );
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          return createUserWithEmailAndPassword(auth, email, password)
            .then((response) => {
              handleUser(response.user, response["_tokenResponse"]);
              dispatch(
                showNotification({
                  message: `Successfully create account`,
                  status: 1,
                })
              );
            })
            .catch((error) => {
              console.error("Error creating user:", error);
              dispatch(
                showNotification({
                  message: `Error creating user: ${error.message}`,
                  status: 0,
                })
              );
            });
        } else if (error.code === "auth/wrong-password") {
          console.log("wrong-password error:", error);
          dispatch(
            showNotification({
              message: `Wrong password. Please try again.`,
              status: 0,
            })
          );
        } else if (error.code === "auth/invalid-email") {
          console.log("invalid-email error:", error);
          dispatch(
            showNotification({
              message: `Invalid Email. Please try again.`,
              status: 0,
            })
          );
        } else if (error.code === "auth/missing-password") {
          console.log("missing-password error:", error);
          dispatch(
            showNotification({
              message: `Please enter the password.`,
              status: 0,
            })
          );
        } else {
          console.error("Error signing in:", error);
          dispatch(
            showNotification({
              message: `Error signing in: ${error.message}`,
              status: 0,
            })
          );
        }
      });
  };

  // const signInWithEmailandPass = (email, password) => {
  //   setLoading(true);
  //   return signInWithEmailAndPassword(auth, email, password)
  //     .then((response) => handleUser(response.user, response["_tokenResponse"]))
  //     .catch((error) => {
  //       if (error.code === "auth/user-not-found") {
  //         return createUserWithEmailAndPassword(auth, email, password)
  //           .then((response) => {
  //             handleUser(response.user, response["_tokenResponse"]);
  //           })
  //           .catch((error) => {
  //             if(error.code === 'auth/auth/wrong-password'){
  //               console.error("Invalid password:", error);
  //             }

  //           });
  //       } else {
  //         console.error("Error signing in:", error);
  //       }
  //     });
  // };

  const signinWithAnnon = () => {
    // const twitterProvider = new TwitterAuthProvider();
    setLoading(true);
    return signInAnonymously(auth).then((response) =>
      handleUser(response.user, response["_tokenResponse"])
    );
  };

  const signout = () => {
    return signOut(auth).then(() => handleUser(false, {}));
  };

  const getTokenCostPerAction = (button_type) => {
    if (subscription?.status === "Creator Plan") {
      switch (button_type) {
        case "Auto_Generate":
          return 0;
        case "Photo_Studio":
          return 0;
        case "Advance_Mode":
          return 0;
        case "TikTok_Reface":
          return 0;
        case "Insta_Clone":
          return 0;
        case "Image_Clone":
          return 0;
        case "Virtual_Clothing":
          return 0;
        case "Voice_Clone":
          //1 Credit can be used for 7 Characters
          return 7;
        //14 Credit can be used for 7 Characters
        case "Talking_Avatar":
          return 7;
        case "Short_Creater":
          return 7;
        case "TikTok_Restyle":
          return 10;
        default:
          return 0;
      }
    } else if (subscription?.status === "Hobbyist Plan") {
      switch (button_type) {
        case "Auto_Generate":
          return 0;
        case "Photo_Studio":
          return 0;
        case "Advance_Mode":
          return 0;
        case "TikTok_Reface":
          return 10;
        case "Insta_Clone":
          return 0;
        case "Image_Clone":
          return 0;
        case "Virtual_Clothing":
          return 0;
        case "Voice_Clone":
          //1 Credit can be used for 7 Characters
          return 7;
        //2 Credit can be used for 7 Characters
        case "Talking_Avatar":
          return 7;
        case "Short_Creater":
          return 7;
        case "TikTok_Restyle":
          return 10;
        default:
          return 0;
      }
    } else if (subscription?.status === "Lite Plan") {
      switch (button_type) {
        case "Auto_Generate":
          return 1;
        case "Photo_Studio":
          return 1;
        case "Advance_Mode":
          return 1;
        case "TikTok_Reface":
          return 10;
        case "Insta_Clone":
          return 1;
        case "Image_Clone":
          return 1;
        case "Virtual_Clothing":
          return 1;
        case "Voice_Clone":
          //1 Credit can be used for 7 Characters
          return 7;
        //2 Credit can be used for 7 Characters
        case "Talking_Avatar":
          return 7;
        case "Short_Creater":
          return 7;
        case "TikTok_Restyle":
          return 10;
        default:
          return 0;
      }
    } else {
      switch (button_type) {
        case "Auto_Generate":
          return 1;
        case "Photo_Studio":
          return 1;
        case "Advance_Mode":
          return 1;
        case "TikTok_Reface":
          return 10;
        case "Insta_Clone":
          return 1;
        case "Image_Clone":
          return 1;
        case "Virtual_Clothing":
          return 1;
        case "Voice_Clone":
          return 20;
        case "Talking_Avatar":
          return 20;
        case "Short_Creater":
          return 20;
        case "TikTok_Restyle":
          return 10;
        default:
          return 0;
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      handleUser(user, {});
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    subscription,
    loading,
    functions,
    db,
    rtdb,
    storage,
    deleteFace,
    signinWithTwitter,
    signinWithGoogle,
    signinWithAnnon,
    signout,
    signInWithEmailandPass,
    getTokenCostPerAction,
  };
}

const checkAuth = () => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page 
      for all internal dashboard routes  */
  const TOKEN = localStorage.getItem("token");
  const PUBLIC_ROUTES = [
    "login",
    "forgot-password",
    "register",
    "documentation",
  ];

  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.href.includes(r)
  );

  if (!TOKEN && !isPublicPage) {
    window.location.href = "/login";
    return;
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

    axios.interceptors.request.use(
      function (config) {
        // UPDATE: Add this code to show global loading indicator
        document.body.classList.add("loading-indicator");
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // UPDATE: Add this code to hide global loading indicator
        document.body.classList.remove("loading-indicator");
        return response;
      },
      function (error) {
        document.body.classList.remove("loading-indicator");
        return Promise.reject(error);
      }
    );
    return TOKEN;
  }
};

export default checkAuth;
